import { defineGetServerData } from '@speedy-js/universal/types';

export default defineGetServerData(async (context) => {
  console.log(context.pathname);
  console.log(context.query);
  return {
    // repos: await fetch('https://vercel-api-wheat.vercel.app/repos.json').then((data) => data.json()),
  };
});

export const shouldBeTreeShaken = () => {};
